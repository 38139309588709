<template>
<div>
  <div v-for="(e, index) in events" v-bind:key="'e_' + index">
    <div v-if="(e.startDate && (!e.endDate || new Date() < e.endDate.toDate())) || !e.startDate" 
      class="p-3 mb-3 rounded shadow"
      :class="[!e.public || e.openRegistration ? 'open' : 'comingsoon']">
      <div>
        <div class="alert-info" v-if="$debug.isOn">{{ e.id }} {{ e.type }}</div>
        <div>
          <font-awesome-icon class="mt-2 mr-2" icon="calendar" :style="{ color: 'grey' }"/>
          <span class="font-weight-bold">{{ e.title }}</span>
          <span class="badge badge-success ml-2" v-if="e.userRoles && e.userIds.includes(user.id)">{{ e.userRoles[user.id] }}</span>
        </div>
        <div class="form-inline sub-mr-2 sub-mt-2">
          <span v-if="getDisplayDate(e)">
            <span class="mr-2">{{ getDisplayDate(e) | formatDate('MMM Do YYYY') }}</span>
            <span class="small">@{{ getDisplayDate(e) | formatDate('LT') }}</span>
          </span>
          <span v-if="e.openRegistration" class="badge badge-info">free</span>
          <span v-if="e.location" class="small">📍{{ e.location }}</span>
        </div>
      </div>
      <div class="form-inline sub-mt-2 sub-mr-2">
        <button class="btn btn-primary" @click="$router.push(`/guest/event/${e.id}`)">Join</button>
        <button v-if="e.openRegistration" class="btn btn-primary" @click="register(e.id)">Register</button>
        <b-dropdown v-if="getDisplayDate(e)" id="dropdown-1" text="Add to Calendar" :disabled="!isRegistered(e.id)">
          <b-dropdown-item @click="openGoogleCalendar(e);">
            Google Calendar
          </b-dropdown-item>
          <b-dropdown-item @click="openMicrosoftCalendar(e);">
            Microsoft Outlook
          </b-dropdown-item>
          <b-dropdown-item @click="openICSCalendar(e);">
            Apple iCalendar
          </b-dropdown-item>
          <b-dropdown-item @click="openICSCalendar(e);">
            Other Calendars (.ics)
          </b-dropdown-item>
        </b-dropdown>
        <shareable class="mt-2" :content="`${$app.appURL}/guest/join/${e.id}`" :config="{hideContent:true}"/>
      </div>
    </div>
    <div v-else-if="$debug.isOn" class="alert-info">
      Past Event {{ e.title }}<br/>
      {{ e.id }}
    </div>
  </div>
</div>
</template>

<script>
import { registerUserToEvent } from "@/services/user.js";
import { ICalendar, OutlookCalendar, GoogleCalendar } from "datebook";
import Shareable from './shareable.vue';

export default {
  components: {
    Shareable
  },
  props: {
    user: Object,
    events: Array,
  },
  data() {
    return {
    };
  },
  methods: {
    getDisplayDate(e) {
      return (e.startDate || e.nextDate)?.toDate();
    },
    isRegistered(eventId) {
      return this.user && this.user.eventRegistrations && this.user.eventRegistrations.includes(eventId);
    },
    register(eventid) {
      registerUserToEvent(this.user, eventid);
    },
    getConfig(e) {
      return {
        title: e.title || (this.$app.productName + ' - Event'),
        location: this.$app.appURL + '/guest/join/' + e.id,
        description: 'Connect your microphone and click the URL: ' + this.$app.appURL + '/guest/join/' + e.id,
        start: this.getDisplayDate(e),
        end: e.endDate?.toDate(),
      };
    },
    openGoogleCalendar(e) {
      let cal = new GoogleCalendar(this.getConfig(e));
      window.open(cal.render(), '_blank');
    },
    openMicrosoftCalendar(e) {
      let cal = new OutlookCalendar(this.getConfig(e));
      window.open(cal.render(), '_blank');
    },
    openICSCalendar(e) {
      let icalendar = new ICalendar(this.getConfig(e));
      icalendar.download();
    }
  }
}
</script>

<style scoped>

.open {
  background: #FFF;
}

.comingsoon {
  background: lightgray;
}

</style>