<template>
<div>
  <div v-if="user" class="m-2 mb-5">
    <b-alert show variant="danger" v-if="errorMessage">
      {{ errorMessage }}
    </b-alert>
    <!-- Promoted Event -->
    <div v-if="$parent.manager.promotedEventId">
      There is a mixer right now <button class="btn btn-primary" @click="this.joinEvent(this.$parent.manager.promotedEventId)">Join</button>
      <hr/>
    </div>
    <!-- Events (Public or Registered) -->
    <div>
      <div v-if="$app.eventsShowPublic" class="text-center mb-3">
        Register to upcoming {{$app.eventName}}s
      </div>
      <event-list
        :events="events"
        :user="user"
        />
    </div>
    <!-- Create Event -->
    <div>
      <device-support location="eventsCreate" @set-unsupported="(v) => unsupported = v"/>
      <div class="form-inline" v-if="user.canCreateEvents"> 
        <button class="btn btn-primary mt-2 mr-2" @click="$router.push('/guest/events/create')"
          :disabled="unsupported">
          Create an Event
        </button>
      </div>
      <b-alert variant="info" :show="true" v-else>
        You've joined as a guest, to participate to the creator program please <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSevwCzlI9pxxvLvVtstM1CjtHO2R0Gz24BmMJsK7MMztzP2Hg/viewform">sign up here</a>.
      </b-alert>
    </div>
  </div>
</div>
</template>

<script>
import { db } from "@/services/db";
import { getCollectionDocsFromIds } from "@/services/dbutils.js";
import { getLog } from "@/services/log";
import deviceSupport from '../../components/deviceSupport.vue';
import EventList from '../../components/eventList.vue';
let log = getLog("events-signup");

export default {
  components: { deviceSupport, EventList },
  data() {
    return {
      events: [],
      errorMessage: null,
      now: new Date(),
      unsupported: false,

      // Debug for display
      debugGenerated: false,
    }
  },
  computed: {
    user() { return this.$parent.user; }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (this.debugGenerated)
        this.generateEvents();
      else {
        if (this.$app.eventsShowPublic) {
          log.log("public events from database");
          this.$bind("events", db.collection("LiveEvents").where("listed", "==", true));
        }
        else if (this.user.eventRegistrations) { // Only registered events
          log.log("events from user.eventRegistrations", this.user.eventRegistrations);
          this.events = await getCollectionDocsFromIds(db.collection("LiveEvents"), this.user.eventRegistrations);
        }
      }
    },
    generateEvents() {
      log.log("debug generateEvents");
      let startTime = "7pm PST";
      let endTime = "7:30pm PST";
      let location = "Greater Los Angeles";
      for (let i = 0; i < 1; ++i) {
        let date = new Date(new Date().setDate(new Date().getDate() + i));
        this.events.push({
          id: date.toISOString().split("T").shift() + "T19:00",
          date: date.toDateString(),
          startTime: startTime,
          endTime: endTime,
          startDate: date,
          endDate: date,
          openRegistration: i < 3,
          location: location,
        });
      }
    },
  }
}
</script>

